import actions from './actions';

const initialState = {
  version: new Date().getTime(),
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  picture: '',
  status: '',
  company: '',
  website: '',
  facebook: '',
  business_type: '',
  sector: '',
  createdAt: '',
  debug: false,
  forgotEmailCodeSent: false,
  authorized: false,
  loading: false,
  remainCredit: 0,
  plan: null,
  feature: null,
  address: {
    countryName: '',
    stateName: '',
    automaticTax: '',
    isAutomaticTaxFailed: null,
    addressCity: '',
    addressLine1: '',
    addressLine2: '',
    taxNumber: '',
    addressPostalCode: '',
    addressCompany: '',
  },
  desiredPlan: {
    maxAds: '',
    name: '',
    id: '',
    isAnnually: '',
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_ADRESS_STATE:
      state.address = action.payload;
      return { ...state };
    case actions.RESET:
      return { ...initialState };
    default:
      return state;
  }
}
