import { API } from 'aws-amplify'

const dashboardApi = {
  get() {
    return API.get('NODE_SERVICE_API', '/dashboard')
      .then(response => response)
      .catch(err => err)
  },
}

export default dashboardApi
