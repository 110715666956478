import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import campaignsApi from 'services/campaignsApi'
import actions from './actions'

export function* GET_CAMPAIGNS() {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(campaignsApi.get)
  if (response) {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CAMPAIGN({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(campaignsApi.create, payload)
  if (response && response.campaign) {
    yield put({
      type: 'campaigns/GET_CAMPAIGNS',
    })
    notification.success({
      message: 'Success',
      description: 'Campaign created',
    })
    yield call(history.push, {
      pathname: '/settings/campaigns',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CAMPAIGN({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(campaignsApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'campaigns/GET_CAMPAIGNS',
    })
    notification.success({
      message: 'Success',
      description: 'Campaign updated',
    })
    yield call(history.push, {
      pathname: '/settings/campaigns',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CAMPAIGN({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(campaignsApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'campaigns/GET_CAMPAIGNS',
    })
    notification.success({
      message: 'Success',
      description: 'Campaign deleted',
    })
    yield call(history.push, '/settings/campaigns')
  } else {
    notification.error({
      message: 'Could not delete campaign',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* GET_CAMPAIGN_DETAILS({ payload }) {
  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(campaignsApi.getDetails, payload)
  if (response && response.status) {
    yield put({
      type: 'campaigns/SET_STATE',
      payload: {
        campaignDetails: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not get campaign details',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'campaigns/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CAMPAIGNS, GET_CAMPAIGNS),
    takeEvery(actions.CREATE_CAMPAIGN, CREATE_CAMPAIGN),
    takeEvery(actions.UPDATE_CAMPAIGN, UPDATE_CAMPAIGN),
    takeEvery(actions.DELETE_CAMPAIGN, DELETE_CAMPAIGN),
    takeEvery(actions.GET_CAMPAIGN_DETAILS, GET_CAMPAIGN_DETAILS),
  ])
}
