import { API } from 'aws-amplify'

const sectorsApi = {
  get() {
    return API.get('NODE_SERVICE_API', '/Sector/all')
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  getGroupped(emotionId) {
    return API.get('NODE_SERVICE_API', `/sectors/grouped${emotionId ? `/${emotionId}` : ''}`)
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  create(data) {
    return API.post('NODE_SERVICE_API', '/sectors', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  update(data) {
    return API.put('NODE_SERVICE_API', '/sectors', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  delete(data) {
    return API.del('NODE_SERVICE_API', '/sectors', { body: data })
      .then(response => response)
      .catch(err => err)
  },
}

export default sectorsApi
