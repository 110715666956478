const actions = {
  SET_STATE: 'images/SET_STATE',
  GET_IMAGE: 'images/GET_IMAGE',
  GET_IMAGES: 'images/GET_IMAGES',
  CREATE_IMAGE: 'images/CREATE_IMAGE',
  UPDATE_IMAGE: 'images/UPDATE_IMAGE',
  DELETE_IMAGE: 'images/DELETE_IMAGE',
}

export default actions
