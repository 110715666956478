const actions = {
  SET_STATE: 'campaigns/SET_STATE',
  GET_CAMPAIGNS: 'campaigns/GET_CAMPAIGNS',
  CREATE_CAMPAIGN: 'campaigns/CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN: 'campaigns/UPDATE_CAMPAIGN',
  DELETE_CAMPAIGN: 'campaigns/DELETE_CAMPAIGN',
  GET_CAMPAIGN_DETAILS: 'campaigns/GET_CAMPAIGN_DETAILS',
}

export default actions
