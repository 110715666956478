import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import subSectorsApi from 'services/subSectorsApi'
import actions from './actions'

export function* GET_SUBSECTORS(payload) {
  yield put({
    type: 'subSectors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(subSectorsApi.get, payload)
  if (response) {
    yield put({
      type: 'subSectors/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'subSectors/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SUBSECTORS, GET_SUBSECTORS),
    // GET_SECTORS(), // run once on app load to check user auth
  ])
}
