import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduce } from 'lodash';
import styles from './style.module.scss';

const mapStateToProps = ({ menu }) => ({
	isMenuTop: menu.isMenuTop,
	menuTopData: menu.menuTopData,
	menuLeftData: menu.menuLeftData
});

@withRouter
@connect(mapStateToProps)
class Breadcrumbs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumb: []
		};
	}

	componentDidMount() {
		this.setBreadcrumbs(this.props);
	}
	// eslint-disable-next-line
	UNSAFE_componentWillReceiveProps(newProps) {
		this.setBreadcrumbs(newProps);
	}

	setBreadcrumbs = (props) => {
		const { isMenuTop, menuTopData, menuLeftData } = this.props;
		this.setState({
			breadcrumb: this.getBreadcrumb(props, isMenuTop ? menuTopData : menuLeftData)
		});
	};

	getPath(data, url, parents = []) {
		const items = reduce(
			data,
			(result, entry) => {
				if (result.length) {
					return result;
				}
				if (entry.url === url) {
					return [ entry ].concat(parents);
				}
				if (entry.children) {
					const nested = this.getPath(entry.children, url, [ entry ].concat(parents));
					return (result || []).concat(nested.filter((e) => !!e));
				}
				return result;
			},
			[]
		);
		return items.length > 0 ? items : [ false ];
	}

	getBreadcrumb = (props, items) => {
		const [ activeMenuItem, ...path ] = this.getPath(items, props.location.pathname);

		if (activeMenuItem && path.length) {
			return path.reverse().map((item, index) => {
				let url = activeMenuItem.url.split('/');
				url.pop();
				url = url.join('/');
				if (url === '/settings') url = '/settings/general';
				if (index === path.length - 1) {
					return (
						<span key={item.key}>
							<span className={`${styles.arrow} text-muted`} />
							<Link key={item.key} to={url}>
								<span className="text-muted">{item.title}</span>
							</Link>
							<span className={styles.arrow} />
							<strong>{activeMenuItem.title}</strong>
						</span>
					);
				}
				return (
					<Link key={item.key} to="/">
						<span className={`${styles.arrow} text-muted`} />
						<span className="text-muted">{item.title}</span>
					</Link>
				);
			});
		}
		return (
			<span>
				<span className={styles.arrow} />
				<strong>{activeMenuItem.title}</strong>
			</span>
		);
	};

	render() {
		const { breadcrumb } = this.state;

		return (
			<div className={styles.breadcrumbs}>
				<div className={styles.path}>
					<Link to="/dashboard" className="text-muted">
						Home
					</Link>
					{breadcrumb}
				</div>
			</div>
		);
	}
}

export default Breadcrumbs;
