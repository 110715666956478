import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user/reducers';
import users from './users/reducers';
import menu from './menu/reducers';
import settings from './settings/reducers';
import transactions from './transactions/reducers';
import purposes from './purposes/reducers';
import reasons from './reasons/reducers';
import sectors from './sectors/reducers';
import subSectors from './subSectors/reducers';
import emotions from './emotions/reducers';
import linkDescriptions from './linkDescriptions/reducers';
import images from './images/reducers';
import dashboard from './dashboard/reducers';
import ads from './ads/reducers';
import myAds from './myAds/reducers';
import algos from './algo/reducers';
import campaigns from './campaigns/reducers';
import primaryTexts from './primarytexts/reducers';
import payment from './payment/reducers';
import userImage from './userImage/reducers';
import campaign from './campaign/reducers';
import country from './country/reducers';

export default (history) => {
  const rootReducerFn = combineReducers({
    router: connectRouter(history),
    user,
    users,
    menu,
    settings,
    purposes,
    reasons,
    sectors,
    subSectors,
    emotions,
    linkDescriptions,
    images,
    transactions,
    dashboard,
    ads,
    myAds,
    algos,
    campaigns,
    primaryTexts,
    payment,
    userImage,
    campaign,
    country,
  });

  return (state, action) => rootReducerFn(action.type === 'RESET' ? undefined : state, action);
};
