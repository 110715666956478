import antdData from 'antd/lib/locale-provider/en_US';
import localeData from 'react-intl/locale-data/en';

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Type to search...',
  'topBar.buyNow': 'Buy Bundle $26',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
};

export const enums = {
  PriceBillingScheme: {
    PerUnit: 1, //
    Tiered: 2, //
  },
  PriceTiersMode: {
    None: 1, //
    Graduated: 2, //
    Volume: 3, //
  },
  PriceType: {
    Onetime: 1, //
    Recurring: 2, //
  },
  PriceRecurringInterval: {
    none: 1, //
    Day: 2, //
    Week: 3, //
    Month: 4, //
    Year: 5, //
  },
  PriceRecurringUsageType: {
    None: 1, //
    Metered: 2, //
    Licensed: 3, //
  },
  PriceRecurringAggregateUsage: {
    None: 1, //
    Sum: 2, //
    LastDuringPeriod: 0, //
    LastEver: 0, //
    Max: 0, //
  },
  SubscriptionStatus: {
    InComplete: 1, //
    Active: 2, //
    Cancelled: 3, //
    Unpaid: 4, //
    Trailing: 5, //
    InCompleteExpired: 6, //
    PastDue: 7, //
  },
  InvoiceStatus: {
    Draft: 1, //
    Open: 2, //
    Paid: 3, //
    Void: 4, //
    Uncollectible: 5, //
  },
  CardCvcCheck: {
    Pass: 1, //
    Fail: 2, //
    Unavailable: 3, //
    Unchecked: 4, //
  },
  CardFunding: {
    Credit: 1, //
    Debit: 2, //
    Prepaid: 3, //
    Unknown: 4, //
  },
  ChargeStatus: {
    Succeded: 1, //
    Pending: 2, //
    Failed: 3, //
  },
  InvoiceBillingReason: {
    AutomaticPendingInvoiceItemInvoice: 1, //
    Manual: 2, //
    Subscription: 3, //
    SubscriptionCreate: 4, //
    SubscriptionCycle: 5, //
    SubscriptionThreshold: 6, //
    SubscriptionUpdate: 7, //
    Upcoming: 8, //
  },
  InvoiceItemType: {
    InvoiceItem: 1, //
    Subscription: 2, //
  },
  PaymentMethod: {
    Payoneer: 1,
    Paypal: 2,
    BankTransfer: 3,
  },
  PaymentStatus: {
    Pending: 1,
    Paid: 2,
    Failed: 3,
    Cancelled: 4,
  },
  AutomaticTax: {
    Supported: 1,
    UnrecognizedLocation: 2,
    NotCollecting: 3,
    Failed: 4,
  },
  TaxVerification: {
    Pending: 1,
    Verified: 2,
    Unverified: 3,
    Unavailable: 4,
    Invalid: 5,
  },
};

const enumsLocale = {
  AlgoName: {
    Main: 'Main',
    RaiseAttendance: 'Raise Attendance',
    SeasonalSpecials: 'Seasonal Specials',
    AdvertiseSale: 'Advertise Sale',
  },
  AdminTheme: {
    Light: 'Light',
    Dark: 'Dark',
  },
  CampaignStatus: {
    NotPublished: 'Not Published',
    Published: 'Published',
  },
  AlgorithmType: {
    Initial: 'Initial',
    Second: 'Second',
    Wildcard: 'Wildcard',
  },
  EmailStatus: {
    Pending: 'Pending',
    Sent: 'Sent',
    Failed: 'Failed',
  },
  PriceBillingScheme: {
    PerUnit: 'Per Unit',
    Tiered: 'Tiered',
  },
  PriceTiersMode: {
    None: 'None',
    Graduated: 'Graduated',
    Volume: 'Volume',
  },
  PriceType: {
    Onetime: 'Onetime',
    Recurring: 'Recurring',
  },
  PriceRecurringInterval: {
    none: 'none',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Year: 'Year',
  },
  PriceRecurringUsageType: {
    None: 'None',
    Metered: 'Metered',
    Licensed: 'Licensed',
  },
  PriceRecurringAggregateUsage: {
    None: 'None',
    Sum: 'Sum',
    LastDuringPeriod: 'Last During Period',
    LastEver: 'Last Ever',
    Max: 'Max',
  },
  SubscriptionStatus: {
    InComplete: 'In Complete',
    Active: 'Active',
    Cancelled: 'Cancelled',
    Unpaid: 'Unpaid',
    Trailing: 'Trailing',
    InCompleteExpired: 'In Complete Expired',
    PastDue: 'Past Due',
  },
  InvoiceStatus: {
    Draft: 'Draft',
    Open: 'Open',
    Paid: 'Paid',
    Void: 'Void',
    Uncollectible: 'Uncollectible',
  },
  CardCvcCheck: {
    Pass: 'Pass',
    Fail: 'Fail',
    Unavailable: 'Unavailable',
    Unchecked: 'Unchecked',
  },
  CardFunding: {
    Credit: 'Credit',
    Debit: 'Debit',
    Prepaid: 'Prepaid',
    Unknown: 'Unknown',
  },
  ChargeStatus: {
    Succeded: 'Succeded',
    Pending: 'Pending',
    Failed: 'Failed',
  },
  InvoiceBillingReason: {
    AutomaticPendingInvoiceItemInvoice: 'Automatic Pending Invoice Item Invoice',
    Manual: 'Manual',
    Subscription: 'Subscription',
    SubscriptionCreate: 'Subscription Create',
    SubscriptionCycle: 'Subscription Cycle',
    SubscriptionThreshold: 'Subscription Threshold',
    SubscriptionUpdate: 'Subscription Update',
    Upcoming: 'Upcoming',
  },
  InvoiceItemType: {
    InvoiceItem: 'Invoice Item',
    Subscription: 'Subscription',
  },
  PaymentMethod: {
    Payoneer: 'Payoneer',
    Paypal: 'Paypal',
    BankTransfer: 'Bank Transfer',
  },
  PaymentStatus: {
    Pending: 'Pending',
    Paid: 'Paid',
    Failed: 'Failed',
    Cancelled: 'Cancelled',
  },
};

export function translateEnum(name, value) {
  try {
    var key = Object.keys(enums[name]).filter((x) => enums[name][x] == value)[0];
    return enumsLocale[name][key];
  } catch (error) {}
  return value;
}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
};
