import React, { memo, useEffect } from 'react';
import { Layout } from 'antd';
import Menu from 'components/UserComponents/Menu';
import Footer from 'components/UserComponents/Footer';
import { useDispatch } from 'react-redux';

const UserLayout = memo(({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isMobileView: false,
        isMobileMenuOpen: false,
        isProfileMenuOpen: false,
        isLightTheme: false,
        isSettingsOpen: false,
        isMenuTop: false,
        isMenuCollapsed: false,
        isBorderless: true,
        isSquaredBorders: false,
        isFixedWidth: false,
        isMenuShadow: true,
        isUserDarkMode: false,
        isFavoriteDrawerOpen: false,
      },
    });
  }, []);

  console.log('UserLayout render');

  return (
    <Layout>
      <Menu />
      <Layout className="users-layout">
        {/* <Layout.Header
          style={{
            background: 'transparent',
          }}>
          <TopBar />
        </Layout.Header> */}
        <Layout.Content
          style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ececec',
          }}>
          {children}
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
      {/* <div style={{ position: 'fixed', left: '50%', height: '100%', backgroundColor: 'red', width: 2 }}></div> */}
    </Layout>
  );
});

export default UserLayout;
