import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import algosApi from 'services/algosApi'
import actions from './actions'

export function* GET_ALGOS() {
  yield put({
    type: 'algos/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(algosApi.get)
  if (response) {
    yield put({
      type: 'algos/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'algos/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_ALGO({ payload }) {
  yield put({
    type: 'algos/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(algosApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'algos/GET_ALGOS',
    })
    notification.success({
      message: 'Success',
      description: 'Algorithm updated',
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'algos/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALGOS, GET_ALGOS), takeEvery(actions.UPDATE_ALGO, UPDATE_ALGO)])
}
