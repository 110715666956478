import actions from './actions';

const initialState = {
  displayPayInvoiceModal: false,
  features: [],
  loading: false,
  cancelLoading: false,
  setupIntent: null,
  cards: [],
  paymentHistory: [],
  subscriptionList: [],
  invoiceList: [],
  previewSubs: {
    tax: '',
    total: '',
    amount: '',
  },
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
