import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import primarytextsApi from 'services/primarytextsApi'
import actions from './actions'

export function* GET_PRIMARYTEXTS() {
  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(primarytextsApi.get)
  if (response) {
    yield put({
      type: 'primarytexts/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_PRIMARYTEXT({ payload }) {
  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(primarytextsApi.create, payload)
  if (response && response.primarytext) {
    yield put({
      type: 'primarytexts/GET_PRIMARYTEXTS',
    })
    notification.success({
      message: 'Success',
      description: 'Primary Text created',
    })
    yield call(history.push, {
      pathname: '/settings/primary-texts',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PRIMARYTEXT({ payload }) {
  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(primarytextsApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'primarytexts/GET_PRIMARYTEXTS',
    })
    notification.success({
      message: 'Success',
      description: 'Primary Text updated',
    })
    yield call(history.push, {
      pathname: '/settings/primary-texts',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_PRIMARYTEXT({ payload }) {
  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(primarytextsApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'primarytexts/GET_PRIMARYTEXTS',
    })
    notification.success({
      message: 'Success',
      description: 'Primary Text deleted',
    })
    yield call(history.push, '/settings/primary-texts')
  } else {
    notification.error({
      message: 'Could not delete primarytext',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'primarytexts/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PRIMARYTEXTS, GET_PRIMARYTEXTS),
    takeEvery(actions.CREATE_PRIMARYTEXT, CREATE_PRIMARYTEXT),
    takeEvery(actions.UPDATE_PRIMARYTEXT, UPDATE_PRIMARYTEXT),
    takeEvery(actions.DELETE_PRIMARYTEXT, DELETE_PRIMARYTEXT),
  ])
}
