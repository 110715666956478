import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import userActions from '../../redux/user/actions';
import './index.scss';
import AntDLoader from 'components/LayoutComponents/Loader/AntDLoader';

export default function Terms() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  const loadingAPI = useSelector((state) => state.user.loading);
  const isApproveAgreement = useSelector((state) => state.user.isApproveAgreement);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isAgree, setIsAgree] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isApproveAgreement) {
      history.push('/#/');
    }
  }, [isApproveAgreement]);

  const cancel = useCallback(() => {
    dispatch({
      type: 'user/LOGOUT',
    });
  }, []);

  const save = useCallback(
    (e) => {
      e.preventDefault();

      form.validateFields().then((values) => {
        dispatch({
          type: userActions.APPROVE_AGGREMENT,
        });
      });
    },
    [dispatch],
  );

  if (isApproveAgreement) {
    return null;
  }

  return (
    <Dialog
      open={isModalOpen}
      PaperProps={{
        style: {
          width: '90%',
          height: '90%',
          minWidth: '90%',
          maxHeight: '90%',
        },
      }}>
      <div className="terms-modal-wrapper">
        <div className="header-terms-area">
          <div className="left-div"></div>
          <div className="mid-div">
            <h3 className="header-terms">ADZY TERMS OF SERVICE</h3>
          </div>
          <div className="right-div"></div>
        </div>
        <div className="terms-modal-area">
          {/* <div dangerouslySetInnerHTML={{ __html: termsDescription }}></div> */}
          <iframe
            style={{ backgroundColor: 'white' }}
            onLoad={() => setLoading(false)}
            src="https://adzy.co/tos/TOS-frame.html"
            width="100%"
            height="100%"
          />
          <Checkbox onChange={(e) => setIsAgree(e.target.checked)}>
            I have read and confirm I fully understand the Terms of Service and agree the terms and conditions stated
            therein.
          </Checkbox>
          <div className="terms-agree-button">
            <Button
              style={{ backgroundColor: '#8ECF25', opacity: !isAgree || loadingAPI ? 0.5 : 1 }}
              type="primary"
              onClick={save}
              disabled={!isAgree || loadingAPI}
              loading={loadingAPI}>
              I AGREE
            </Button>
            <Button
              style={{ backgroundColor: '#808080', marginLeft: 100 }}
              type="primary"
              onClick={() => {
                cancel();
              }}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
