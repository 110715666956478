import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { apiCall } from 'services/utils';
import { history } from 'index'

export function* CAMPAIGN_GET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      campaignId: payload,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'GET',
      url: 'Campaign/myAds/' + payload,
    }),
  );
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        campaignData: response.value,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}
export function* CAMPAIGN_SAVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const isCreate = !payload.id;
  const response = yield call(() =>
    apiCall({
      url: 'Campaign/custom/upsert',
      data: payload,
    }),
  );

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
  if (response.success) {
    history.push('/campaign/' + response.value.id + '/view');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CAMPAIGN_SAVE, CAMPAIGN_SAVE), takeEvery(actions.CAMPAIGN_GET, CAMPAIGN_GET)]);
}
