import { API } from 'aws-amplify';

export default {};

export const countryApi = {
  get() {
    return API.get('NODE_SERVICE_API', '/Country/list')
      .then((response) => response)
      .catch((err) => err);
  },

  getStateList(data) {
    console.log(data, 'payload');
    const { id } = data.payload;
    console.log(`/Country/Statelist/${id}`, 'sample');

    return API.get('NODE_SERVICE_API', `/Country/Statelist/${id}`)
      .then((response) => response)
      .catch((err) => err);
  },
};
