const actions = {
  SET_STATE: 'purposes/SET_STATE',
  GET_PURPOSES: 'purposes/GET_PURPOSES',
  GET_PURPOSE: 'purposes/GET_PURPOSE',
  CREATE_PURPOSE: 'purposes/CREATE_PURPOSE',
  UPDATE_PURPOSE: 'purposes/UPDATE_PURPOSE',
  DELETE_PURPOSE: 'purposes/DELETE_PURPOSE',
  GET_PURPOSES_GROUPPED: 'purposes/GET_PURPOSES_GROUPPED',
}

export default actions
