import { all, takeEvery, put, call, delay } from 'redux-saga/effects';
import actions from './actions';
import { countryApi } from 'services/country.js';
import { processResponse } from 'services/utils';

export function* GET_COUNTRY(payload) {
  yield put({
    type: 'country/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(countryApi.get, payload);
  if (processResponse(response, true)) {
    yield put({
      type: 'country/SET_STATE',
      payload: {
        allCountry: response.value,
      },
    });
  }

  yield put({
    type: 'country/SET_STATE',
    payload: {
      loading: false,
    },
  });

}

export function* GET_STATELIST(payload) {
  yield put({
    type: 'country/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(countryApi.getStateList, payload);
  if (processResponse(response, true)) {
    yield put({
      type: 'country/SET_STATE',
      payload: {
        // selectedCountryStates: response.value,
        selectedCountryStates: [
          { name: '10', countryId: 10 },
          { name: '20', countryId: 20 },
        ],
      },
    });
  }

  yield put({
    type: 'country/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ALL_COUNTRY, GET_COUNTRY), takeEvery(actions.GET_STATE_LIST, GET_STATELIST)]);
}
