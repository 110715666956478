const actions = {
  SET_STATE: 'sectors/SET_STATE',
  GET_SECTORS: 'sectors/GET_SECTORS',
  GET_SECTORS_GROUPPED: 'sectors/GET_SECTORS_GROUPPED',
  CREATE_SECTOR: 'sectors/CREATE_SECTOR',
  UPDATE_SECTOR: 'sectors/UPDATE_SECTOR',
  DELETE_SECTOR: 'sectors/DELETE_SECTOR',
}

export default actions
