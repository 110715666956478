import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { history, store as reduxStore } from 'index';
import { processResponse } from 'services/utils';
import { notification } from 'antd';
import sectorsApi from 'services/sectorsApi';
import actions from './actions';

export function* GET_SECTORS() {
  const state = yield select(x => x.sectors);
  
  // do not load sectors anymore!
  if (state.data && (state.loading || state.data.length > 0)) {
    return;
  }
  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(sectorsApi.get);
  if (processResponse(response, true)) {
    yield put({
      type: 'sectors/SET_STATE',
      payload: {
        data: response.value.filter(x => !x.parentId),
        all: response.value,
      },
    });
  }

  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* CREATE_SECTOR({ payload }) {
  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(sectorsApi.create, payload);
  if (response && response.sector) {
    yield put({
      type: 'sectors/GET_SECTORS',
    });
    notification.success({
      message: 'Success',
      description: 'Sector created',
    });
    yield call(history.push, {
      pathname: '/settings/sectors',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_SECTOR({ payload }) {
  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(sectorsApi.update, payload);
  if (response && response.status) {
    yield put({
      type: 'sectors/GET_SECTORS',
    });
    notification.success({
      message: 'Success',
      description: 'Sector updated',
    });
    yield call(history.push, {
      pathname: '/settings/sectors',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* DELETE_SECTOR({ payload }) {
  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(sectorsApi.delete, payload);
  if (response && response.status) {
    yield put({
      type: 'sectors/GET_SECTORS',
    });
    notification.success({
      message: 'Success',
      description: 'Sector deleted',
    });
    yield call(history.push, '/settings/sectors');
  } else {
    notification.error({
      message: 'Could not delete sector',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'sectors/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* GET_SECTORS_GROUPPED() {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(sectorsApi.getGroupped);
  if (response) {
    yield put({
      type: 'sectors/SET_STATE',
      payload: {
        grouped: response,
      },
    });
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SECTORS, GET_SECTORS),
    takeEvery(actions.GET_SECTORS_GROUPPED, GET_SECTORS_GROUPPED),
    takeEvery(actions.CREATE_SECTOR, CREATE_SECTOR),
    takeEvery(actions.UPDATE_SECTOR, UPDATE_SECTOR),
    takeEvery(actions.DELETE_SECTOR, DELETE_SECTOR),
  ]);
}
