const actions = {
  SET_STATE: 'emotions/SET_STATE',
  GET_EMOTIONS: 'emotions/GET_EMOTIONS',
  GET_EMOTIONS_GROUPPED: 'emotions/GET_EMOTIONS_GROUPPED',
  GET_EMOTION: 'emotions/GET_EMOTION',
  CREATE_EMOTION: 'emotions/CREATE_EMOTION',
  UPDATE_EMOTION: 'emotions/UPDATE_EMOTION',
  DELETE_EMOTION: 'emotions/DELETE_EMOTION',
}

export default actions
