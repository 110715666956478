const actions = {
  RESET: 'ads/RESET',
  SET_STATE: 'ads/SET_STATE',
  GET_ADS: 'ads/GET_ADS',
  CREATE_AD: 'ads/CREATE_AD',
  DOWNLOAD_AD: 'ads/DOWNLOAD_AD',
  SEND_AD: 'ads/SEND_AD',
  BUY: 'ads/BUY',
  GET_ADIMAGES: 'ads/GET_ADIMAGES',
  GET_MYADS: 'ads/GET_MYADS',
  GET_PRIMARYTEXTS: 'ads/GET_PRIMARYTEXTS',
}

export default actions
