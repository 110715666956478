import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import transactionsApi from 'services/transactionsApi'
import actions from './actions'

export function* GET_TRANSACTIONS() {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(transactionsApi.get)
  if (response) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TRANSACTIONS, GET_TRANSACTIONS)])
}
