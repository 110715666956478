import Amplify, { Auth } from 'aws-amplify'

export default Amplify.configure({
  Auth: {
    //identityPoolId: 'eu-west-2:7257ca36-5b30-4a74-8dbf-69f4f25b19ec',
    //region: 'eu-west-2',
    //userPoolId: 'eu-west-2_hsQINehic',
    //userPoolWebClientId: '626g1jsl2ehuighs0d5r4cg0gc',
    //mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        name: 'NODE_SERVICE_API',
        endpoint: process.env.REACT_APP_API,
        region: 'eu-west-2',
        custom_header: async () => {
          //const resP = await currentUserToken()
          //return resP
          return {
            token: localStorage.getItem('token'),
          }
        },
      },
    ],
  },
})
