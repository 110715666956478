import { all, takeEvery, put, call, delay } from 'redux-saga/effects';
import { notification } from 'antd';
import amplifyService from 'services/awsamplify';
import { history } from 'index';
import { processResponse } from 'services/utils';
import { usersApi } from 'services/user';
import actions from './actions';
import {
  apiCall,
  convertModelToFormData,
  gtmLogLogout,
  gtmLogRegister,
  gtmLogEmailVerified,
  encodeBase64,
} from '../../services/utils';

export function* LOGIN({ payload }) {
  const { email, password } = payload;

  yield put({
    type: 'user/RESET',
  });

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.login, { email, password });

  if (processResponse(response)) {
    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in to Adzy!',
    // });
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* REGISTER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.registerAccount, payload);
  if (processResponse(response, true)) {
    if (response.value && response.value.accessToken) {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      });
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          email: payload.email,
        },
      });

      gtmLogRegister(payload.email);

      yield call(history.push, {
        pathname: '/verification',
        state: history.location && history.location.state,
        search: '?e=' + encodeBase64(payload.email),
      });
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* CHECK_REGISTER_PROMOTION({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.checkPromotion, payload);
  // console.log(response, 'response');
  if (!response.success) {
    notification.error({
      message: 'Invalid promotion parameters!',
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
    return;
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      userPromotionData: payload,
      loading: false,
    },
  });
}

export function* LOAD_CURRENT_ACCOUNT(data) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.getAccountData);
  if (processResponse(response, true)) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...response.value,
        company: response.value.company || '',
        website: response.value.website || '',
        facebook: response.value.facebook || '',
        plan: response.value.plan,
        feature: response.value.feature,
        authorized: true,
        version: new Date().getTime(),
        currentSubscription: response.value.currentSubscription,
      },
    });
    if (data && data.callback) {
      data.callback();
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOGOUT() {
  //yield call(amplifyService.logout);
  gtmLogLogout(localStorage.getItem('email'));
  localStorage.clear();
  yield put({
    type: 'RESET',
  });
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(() => apiCall({ url: 'Account/recover', data: { email } }), { email });

  if (response.success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        forgotEmailCodeSent: true,
      },
    });
    notification.success({
      message: 'Success',
      description: `A verification code was sent to your email`,
    });
  } else {
    notification.error({
      message: 'Account Recovery Failed',
      description: 'Account recovery failed, please try again later.',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* FORGOT_PASSWORD_SUBMIT({ payload }) {
  const { email, code, password } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(amplifyService.recoveryPasswordSubmit, email, code, password);

  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        forgotEmailCodeSent: false,
      },
    });
    notification.success({
      message: 'Success',
      description: `Your account was succesfully recovered`,
    });

    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Account Recovery Failed',
      description: response.message || 'Account recovery failed, please try again later.',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* CHANGE_PASSWORD({ payload }) {
  const { oldPassword, newPassword } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.changePassword, { oldPassword, newPassword });
  if (processResponse(response)) {
    notification.success({
      message: 'Success',
      description: `Your account was succesfully recovered`,
    });

    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* VERIFY_CODE({ payload }) {
  const { email, code } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.verifyEmailCode, { email, code });
  if (processResponse(response)) {
    notification.success({
      message: 'Success',
      description: `Your account was succesfully verified`,
    });

    gtmLogEmailVerified(email);

    yield call(history.push, {
      pathname: '/login',
      state: history.location && history.location.state,
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.update, payload);
  if (response && response._id) {
    yield put({
      type: 'user/GET_USERS',
    });
    notification.success({
      message: 'Success',
      description: 'User updated',
    });
    yield call(history.push, {
      pathname: '/users',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* SEND_FEEDBACK({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.sendFeedback, payload);
  if (processResponse(response)) {
    notification.success({
      message: 'Success',
      description: 'Thank you for your feedback',
    });
  } else {
    notification.error({
      message: 'Could not send feedback',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* GET_ADRESS() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.getAdress);
  if (processResponse(response, true)) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        address: response.value,
        addressLoaded: true,
      },
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_ADRESS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      saved: false,
    },
  });

  const response = yield call(usersApi.updateAdress, payload);
  if (processResponse(response, true)) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        address: { ...payload, ...response.value },
        saved: true,
      },
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* CREATE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.create, payload);
  if (response && response.status && response.status !== 'BAD') {
    yield put({
      type: 'user/GET_USERS',
    });
    notification.success({
      message: 'Success',
      description: 'User created',
    });
    yield call(history.push, {
      pathname: '/users',
      state: history.location && history.location.state,
    });
  } else {
    notification.error({
      message: 'Could not create data',
      description: (response && response.message) || 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* DELETE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.delete, payload);
  if (response && response.status) {
    yield put({
      type: 'users/GET_USERS',
    });
    notification.success({
      message: 'Success',
      description: 'User deleted',
    });
    yield call(history.push, '/users');
  } else {
    notification.error({
      message: 'Could not delete sector',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* UPDATE_ACCOUNT({ payload }) {
  const { data, forceReload } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.updateAccount, data);
  if (processResponse(response, true)) {
    notification.success({
      message: 'Success',
      description: 'User updated',
    });
    if (forceReload) {
      window.location.reload();
    } else {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      });
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* UPDATE_ACCOUNT_SECTOR({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response = yield call(usersApi.updateUserSector, payload);
  if (processResponse(response, true)) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* UPDATE_ACCOUNT_COMPANY({ payload, callback }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  //const response = yield call(usersApi.updateUserCompany, payload);
  var formData = convertModelToFormData(payload, '');
  const response = yield call(() =>
    apiCall({
      url: 'User/updateCompany',
      data: formData,
      timeout: 600000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  );

  if (response.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      callback: callback,
    });
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}
export function* APPROVE_AGGREMENT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response = yield call(usersApi.approveAggrement, payload);

  if (processResponse(response)) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isApproveAgreement: true,
        loading: false,
      },
    });
    return;
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.UPDATE_ADRESS, UPDATE_ADRESS),
    takeEvery(actions.GET_ADRESS, GET_ADRESS),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.CHECK_REGISTER_PROMOTION, CHECK_REGISTER_PROMOTION),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.FORGOT_PASSWORD_SUBMIT, FORGOT_PASSWORD_SUBMIT),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.SEND_FEEDBACK, SEND_FEEDBACK),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.DELETE_USER, DELETE_USER),
    takeEvery(actions.UPDATE_ACCOUNT, UPDATE_ACCOUNT),
    takeEvery(actions.UPDATE_ACCOUNT_SECTOR, UPDATE_ACCOUNT_SECTOR),
    takeEvery(actions.UPDATE_ACCOUNT_COMPANY, UPDATE_ACCOUNT_COMPANY),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.VERIFY_CODE, VERIFY_CODE),
    takeEvery(actions.APPROVE_AGGREMENT, APPROVE_AGGREMENT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
