import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import linkDescriptionsApi from 'services/linkDescriptionsApi'
import actions from './actions'

export function* GET_LINKDESCRIPTIONS() {
  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(linkDescriptionsApi.get)
  if (response) {
    yield put({
      type: 'linkDescriptions/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_LINKDESCRIPTION({ payload }) {
  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(linkDescriptionsApi.create, payload)
  if (response && response.description) {
    yield put({
      type: 'linkDescriptions/GET_LINKDESCRIPTIONS',
    })
    notification.success({
      message: 'Success',
      description: 'Link Description created',
    })
    yield call(history.push, {
      pathname: '/settings/link-descriptions',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_LINKDESCRIPTION({ payload }) {
  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(linkDescriptionsApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'linkDescriptions/GET_LINKDESCRIPTIONS',
    })
    notification.success({
      message: 'Success',
      description: 'Link Description updated',
    })
    yield call(history.push, {
      pathname: '/settings/link-descriptions',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_LINKDESCRIPTION({ payload }) {
  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(linkDescriptionsApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'linkDescriptions/GET_LINKDESCRIPTIONS',
    })
    notification.success({
      message: 'Success',
      description: 'Link Description deleted',
    })
    yield call(history.push, '/settings/link-descriptions')
  } else {
    notification.error({
      message: 'Could not delete linkDescription',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'linkDescriptions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LINKDESCRIPTIONS, GET_LINKDESCRIPTIONS),
    takeEvery(actions.CREATE_LINKDESCRIPTION, CREATE_LINKDESCRIPTION),
    takeEvery(actions.UPDATE_LINKDESCRIPTION, UPDATE_LINKDESCRIPTION),
    takeEvery(actions.DELETE_LINKDESCRIPTION, DELETE_LINKDESCRIPTION),
  ])
}
