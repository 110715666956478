import React from 'react';
import styles from './style.module.scss';
import json from '../../../../package.json';

const Footer = () => (
  <div className={styles.footer}>
    <div></div>
    <div>
      <a href="https://adzy.co/adzy-terms-of-service/" target="_blank" rel="noopener noreferrer">
        Terms of Use
      </a>{' '}
      &nbsp; | &copy; Enigma Innovations Group Limited {new Date().getUTCFullYear()} &nbsp; | &nbsp; v{json.version} &nbsp; | &nbsp;
      <a href="https://adzy.co/privacy-policy/" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
    </div>
  </div>
);

export default Footer;
