import { API } from 'aws-amplify'

export default {
  get({ payload: sector }) {
    return API.get('NODE_SERVICE_API', `/subsectors/${sector}`)
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
}
