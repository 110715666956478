import actions from './actions';

const initialState = {
  data: [],
  images: [],
  loading: false,
  title: '',
  displayImageUpload: false,
  uploadedImage: null,
  loadingList: true,
  isSuccess: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
