import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import imagesApi from 'services/imagesApi'
import actions from './actions'

export function* GET_IMAGE({ payload }) {
  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(imagesApi.getImage, payload)
  if (response) {
    yield put({
      type: 'images/SET_STATE',
      payload: {
        image: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* GET_IMAGES() {
  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(imagesApi.get)
  if (response) {
    yield put({
      type: 'images/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_IMAGE({ payload }) {
  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(imagesApi.create, payload)
  if (response && response._id) {
    yield put({
      type: 'images/GET_IMAGES',
    })
    notification.success({
      message: 'Success',
      description: 'Image created',
    })
    yield call(history.push, {
      pathname: `/settings/image-database/edit/${response._id}`,
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_IMAGE({ payload }) {
  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(imagesApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'images/GET_IMAGES',
    })
    notification.success({
      message: 'Success',
      description: response.isEdit ? 'Image updated' : 'Image created',
    })
    // yield call(history.push, {
    //   pathname: '/settings/image-database',
    //   state: history.location && history.location.state,
    // })
    yield call(history.push, {
      pathname: `/settings/image-database/edit/${response.id}`,
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_IMAGE({ payload }) {
  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(imagesApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'images/GET_IMAGES',
    })
    notification.success({
      message: 'Success',
      description: 'Image deleted',
    })
    yield call(history.push, '/settings/image-database')
  } else {
    notification.error({
      message: 'Could not delete image',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'images/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_IMAGE, GET_IMAGE),
    takeEvery(actions.GET_IMAGES, GET_IMAGES),
    takeEvery(actions.CREATE_IMAGE, CREATE_IMAGE),
    takeEvery(actions.UPDATE_IMAGE, UPDATE_IMAGE),
    takeEvery(actions.DELETE_IMAGE, DELETE_IMAGE),
  ])
}
