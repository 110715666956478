import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';
import reducers from 'redux/reducers';
import sagas from 'redux/sagas';
import Router from 'router';
import Localization from 'components/LayoutComponents/Localization';
import Amplify from 'aws-amplify';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';

import awsconfig from './aws-exports';
// app styles
import './global.scss';

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
if (process.env.NODE_ENV === 'development' && true) {
  // middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);

Amplify.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <Localization>
        <Router history={history} />
      </Localization>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
export { store, history };
