import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'
import qs from 'qs'
import { history, store as reduxStore } from 'index'
import settingsApi from 'services/settingsApi'
import actions from './actions'

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SETUP() {
  // load settings from url on app load
  const changeSettings = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    Object.keys(query).forEach(key => {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: key,
          value: query[key] === 'true',
        },
      })
    })
  }
  yield changeSettings(history.location.search)
  yield history.listen(params => {
    const { search } = params
    changeSettings(search)
  })

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      })
    }
  }
  yield isMobileView(true)
  yield window.addEventListener('resize', () => {
    isMobileView()
  })
}

export function* GET_SETTINGS() {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(settingsApi.get)
  if (response) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SETTING({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(settingsApi.get, payload)
  if (response) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        setting: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_SETTING({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(settingsApi.create, payload)
  if (response && response.SETTING) {
    yield put({
      type: 'settings/GET_SETTINGS',
    })
    notification.success({
      message: 'Success',
      description: 'Setting created',
    })
    yield call(history.push, {
      pathname: '/settings/general',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_SETTING({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(settingsApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'settings/GET_SETTINGS',
    })
    notification.success({
      message: 'Success',
      description: 'Setting updated',
    })
    yield call(history.push, {
      pathname: '/settings/general',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.GET_SETTINGS, GET_SETTINGS),
    takeEvery(actions.CREATE_SETTING, CREATE_SETTING),
    takeEvery(actions.UPDATE_SETTING, UPDATE_SETTING),
    takeEvery(actions.GET_SETTING, GET_SETTING),
    SETUP(), // run once on app load to init listeners
    //GET_SETTINGS(),
  ])
}
