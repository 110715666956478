import React from 'react'
import { Button } from 'antd'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.copyright}>
        <span>© 2019 Adzy. All rights reserved</span>
      </div>
    </div>
  </div>
)

export default Footer
