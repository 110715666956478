const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  GET_SETTINGS: 'settings/GET_SETTINGS',
  GET_SETTING: 'settings/GET_SETTING',
  CREATE_SETTING: 'settings/CREATE_SETTING',
  UPDATE_SETTING: 'settings/UPDATE_SETTING',
}

export default actions
