import actions from './actions';

const initialState = {
  data: [],
  loading: false
};

export default function adsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return { ...initialState };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
