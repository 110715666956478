import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { notification } from 'antd'
import reasonsApi from 'services/reasonsApi'
import actions from './actions'

export function* GET_REASONS() {
  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reasonsApi.get)
  if (response) {
    yield put({
      type: 'reasons/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_REASON({ payload }) {
  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reasonsApi.create, payload)
  if (response && response.reason) {
    yield put({
      type: 'reasons/GET_REASONS',
    })
    notification.success({
      message: 'Success',
      description: 'Reason created',
    })
    yield call(history.push, {
      pathname: '/settings/reasons',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_REASON({ payload }) {
  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reasonsApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'reasons/GET_REASONS',
    })
    notification.success({
      message: 'Success',
      description: 'Reason updated',
    })
    yield call(history.push, {
      pathname: '/settings/reasons',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_REASON({ payload }) {
  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(reasonsApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'reasons/GET_REASONS',
    })
    notification.success({
      message: 'Success',
      description: 'Reason deleted',
    })
    yield call(history.push, '/settings/reasons')
  } else {
    notification.error({
      message: 'Could not delete reason',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'reasons/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REASONS, GET_REASONS),
    takeEvery(actions.CREATE_REASON, CREATE_REASON),
    takeEvery(actions.UPDATE_REASON, UPDATE_REASON),
    takeEvery(actions.DELETE_REASON, DELETE_REASON),
  ])
}
