import 'rc-drawer/assets/index.css';
import React, { memo, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Drawer, Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'index';
import { CheckPeriodEnded, l10n } from 'services/utils';

const AppMenu = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user || {});
  const settings = useSelector((state) => state.settings || {});
  const { feature, currentSubscription, plan } = useSelector((state) => state.user);

  const { isMobileMenuOpen, isProfileMenuOpen, isFavoriteDrawerOpen } = settings;
  const closeAll = () => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isProfileMenuOpen: false,
        isMobileMenuOpen: false,
        isFavoriteDrawerOpen: false,
      },
    });
  };

  const { isPeriodEnded } = CheckPeriodEnded(currentSubscription);

  const toggleOpen = useCallback(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isFavoriteDrawerOpen: false,
        isProfileMenuOpen: false,
        isMobileMenuOpen: !isMobileMenuOpen,
      },
    });
  }, [isMobileMenuOpen]);
  const openAccountScreen = useCallback(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isFavoriteDrawerOpen: false,
        isMobileMenuOpen: false,
        isProfileMenuOpen: !isProfileMenuOpen,
      },
    });
  }, [isProfileMenuOpen]);
  const openFavoritesScreen = useCallback(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isProfileMenuOpen: false,
        isMobileMenuOpen: false,
        isFavoriteDrawerOpen: !isFavoriteDrawerOpen,
      },
    });
  }, [isFavoriteDrawerOpen]);
  const logout = useCallback(() => {
    dispatch({
      type: 'user/LOGOUT',
    });
  }, []);

  const createAnAdLink = useCallback(() => {
    // const { location: { pathname } } = history;
    closeAll();
    // if(pathname === "/users/create-ad") return window.location.reload();
    return history.push('/users/create-ad');
  }, [history]);

  const goToFeedback = useCallback(() => {
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isProfileMenuOpen: false,
        isMobileMenuOpen: false,
        isFavoriteDrawerOpen: false,
      },
    });
    return history.push('/users/feedback');
  }, []);

  const goToPlans = useCallback(() => {
    closeAll();
    return history.push('/users/plans');
  }, []);
  const goToCards = useCallback(() => {
    closeAll();
    return history.push('/users/payments');
  }, []);
  const goToHistory = useCallback(() => {
    closeAll();
    return history.push('/users/payment-history');
  }, []);
  const goToPlanDetail = useCallback(() => {
    closeAll();
    return history.push('/users/plan-detail');
  }, []);
  const goToPage = (url) => () => {
    closeAll();
    return history.push(url);
  };

  return (
    <Drawer
      open={isMobileMenuOpen}
      placement="right"
      maskClosable
      width="300px"
      closable={false}
      onClose={toggleOpen}
      className="user-webmenu">
      {isMobileMenuOpen && (
        <div className="top">
          <Button onClick={toggleOpen}>
            <CloseOutlined />
          </Button>
        </div>
      )}

      <div className="menu-content">
        <div className="welcome">
          <h3>Hello {user.name}</h3>
          <p>Nice to see you</p>
        </div>
        <ul className="main-navigation">
          <li>
            <Button
              disabled={feature.isSuspended || isPeriodEnded || plan.isIdle}
              onClick={createAnAdLink}
              className="menuTitle">
              Create an Ad
            </Button>
          </li>
          <li>
            <Button onClick={goToPage('/your-details')} className="menuTitle">
              Your Details
            </Button>
            <div className="infoText">Edit name, change password, update company and Facebook details</div>
          </li>

          <li>
            <Button onClick={goToPlans} className="menuTitle">
              Plans
            </Button>
            <div className="infoText">Current plan, ad allowance and usage, change plan, cancel, close account</div>
          </li>
          <li>
            <Button onClick={goToCards} className="menuTitle">
              Payments
            </Button>
            <div className="infoText">View bills, payment history, download invoices, add/edit payment methods</div>
          </li>
          {feature.uploadOwnImage && (
            <li>
              <Button
                disabled={feature.isSuspended || isPeriodEnded || plan.isIdle}
                onClick={goToPage('/users/image')}
                className="menuTitle">
                Your Image Library
              </Button>
              <div className="infoText"></div>
            </li>
          )}

          {/* <li>
            <Button onClick={goToHistory} className="menuTitle">
              Payment History
            </Button>
          </li>
          <li>
            <Button onClick={goToPlanDetail} className="menuTitle">
              Plan Detail
            </Button>
          </li>
          <li>
            <Button onClick={goToPage('/users/profile')} className="menuTitle">
              My Account
            </Button>
          </li>
          <li>
            <Button onClick={goToPage('/campaign/new')} className="menuTitle">
              New Campaign
            </Button>
          </li> */}
        </ul>
        <ul className="secondary-navigation">
          <li>
            <a target="_blank" href="https://adzy.tawk.help/">
              Help and support
            </a>
          </li>
          <li>
            <Button onClick={goToFeedback}>Send feedback</Button>
          </li>
          <li>
            <a href="mailto:team@adzy.co?subject=Close%20Adzy%20Account">Close Account</a>
          </li>
        </ul>
      </div>
      <div className="signOut mb30">
        <Button onClick={logout}>Sign out</Button>
      </div>
    </Drawer>
  );
});

export default AppMenu;
