import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'

import { processResponse } from 'services/utils'
import { notification } from 'antd'
import emotionsApi from 'services/emotionsApi'
import actions from './actions'

export function* GET_EMOTIONS() {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(emotionsApi.get)
  if (response) {
    yield put({
      type: 'emotions/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_EMOTIONS_GROUPPED() {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(emotionsApi.getGroupped)
  if (processResponse(response, true)) {
    yield put({
      type: 'emotions/SET_STATE',
      payload: {
        grouped: response.value,
      },
    })
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_EMOTION({ payload }) {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(emotionsApi.get, payload)
  if (response) {
    yield put({
      type: 'emotions/SET_STATE',
      payload: {
        emotion: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_EMOTION({ payload }) {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(emotionsApi.create, payload)
  if (response && response.emotion) {
    yield put({
      type: 'emotions/GET_EMOTIONS',
    })
    notification.success({
      message: 'Success',
      description: 'Emotion created',
    })
    yield call(history.push, {
      pathname: '/settings/emotions',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_EMOTION({ payload }) {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(emotionsApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'emotions/GET_EMOTIONS',
    })
    notification.success({
      message: 'Success',
      description: 'Emotion updated',
    })
    yield call(history.push, {
      pathname: '/settings/emotions',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_EMOTION({ payload }) {
  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(emotionsApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'emotions/GET_EMOTIONS',
    })
    notification.success({
      message: 'Success',
      description: 'Emotion deleted',
    })
    yield call(history.push, '/settings/emotions')
  } else {
    notification.error({
      message: 'Could not delete emotion',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'emotions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_EMOTIONS, GET_EMOTIONS),
    takeEvery(actions.GET_EMOTIONS_GROUPPED, GET_EMOTIONS_GROUPPED),
    takeEvery(actions.CREATE_EMOTION, CREATE_EMOTION),
    takeEvery(actions.UPDATE_EMOTION, UPDATE_EMOTION),
    takeEvery(actions.DELETE_EMOTION, DELETE_EMOTION),
    takeEvery(actions.GET_EMOTION, GET_EMOTION),
  ])
}
