import { API } from 'aws-amplify'

const purposesApi = {
  get(purposeId) {
    return API.get('NODE_SERVICE_API', `/campaigns${purposeId ? `/${purposeId}` : ''}`)
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  create(data) {
    return API.post('NODE_SERVICE_API', '/campaigns', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  update(data) {
    return API.put('NODE_SERVICE_API', '/campaigns', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  delete(data) {
    return API.del('NODE_SERVICE_API', '/campaigns', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  getDetails(campaignId) {
    return API.get('NODE_SERVICE_API', `/campaigns/details/${campaignId}`, {})
      .then(response => response)
      .catch(err => err)
  },
}

export default purposesApi
