import { API } from 'aws-amplify'

const transactionsApi = {
  get() {
    return API.get('NODE_SERVICE_API', '/transactions')
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
}

export default transactionsApi
