import { all, takeEvery, put, call } from 'redux-saga/effects'
import { history, store as reduxStore } from 'index'
import { processResponse } from 'services/utils'
import { notification } from 'antd'
import purposesApi from 'services/purposesApi'
import actions from './actions'

export function* GET_PURPOSES() {
  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(purposesApi.get)
  if (response) {
    yield put({
      type: 'purposes/SET_STATE',
      payload: {
        data: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PURPOSES_GROUPPED() {
  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(purposesApi.getGroupped)
  if (processResponse(response, true)) {
    yield put({
      type: 'purposes/SET_STATE',
      payload: {
        grouped: response.value,
      },
    })
  }

  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PURPOSE({ payload }) {
  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(purposesApi.get, payload)
  if (response) {
    yield put({
      type: 'purposes/SET_STATE',
      payload: {
        purpose: response,
      },
    })
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_PURPOSE({ payload }) {
  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(purposesApi.create, payload)
  if (response && response.purpose) {
    yield put({
      type: 'purposes/GET_PURPOSES',
    })
    notification.success({
      message: 'Success',
      description: 'Purpose created',
    })
    yield call(history.push, {
      pathname: '/settings/purposes',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not create data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PURPOSE({ payload }) {
  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(purposesApi.update, payload)
  if (response && response.status) {
    yield put({
      type: 'purposes/GET_PURPOSES',
    })
    notification.success({
      message: 'Success',
      description: 'Purpose updated',
    })
    yield call(history.push, {
      pathname: '/settings/purposes',
      state: history.location && history.location.state,
    })
  } else {
    notification.error({
      message: 'Could not update data',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_PURPOSE({ payload }) {
  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(purposesApi.delete, payload)
  if (response && response.status) {
    yield put({
      type: 'purposes/GET_PURPOSES',
    })
    notification.success({
      message: 'Success',
      description: 'Purpose deleted',
    })
    yield call(history.push, '/settings/purposes')
  } else {
    notification.error({
      message: 'Could not delete purpose',
      description: 'System could not communicate properly with the endpoints',
    })
  }

  yield put({
    type: 'purposes/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PURPOSES, GET_PURPOSES),
    takeEvery(actions.CREATE_PURPOSE, CREATE_PURPOSE),
    takeEvery(actions.UPDATE_PURPOSE, UPDATE_PURPOSE),
    takeEvery(actions.DELETE_PURPOSE, DELETE_PURPOSE),
    takeEvery(actions.GET_PURPOSE, GET_PURPOSE),
    takeEvery(actions.GET_PURPOSES_GROUPPED, GET_PURPOSES_GROUPPED),
  ])
}
