import { Auth } from 'aws-amplify'

const awsAmplifyService = {
  login(email, password) {
    return Auth.signIn(email, password)
      .then(user => user)
      .catch(err => err)
  },
  logout() {
    return Auth.signOut()
      .then(data => data)
      .catch(err => err)
  },
  registerUser(name, email, password, accountType = 2) {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        zoneinfo: accountType,
      },
    })
      .then(data => data)
      .catch(err => err)
  },
  recoveryPassword(email) {
    return Auth.forgotPassword(email)
      .then(data => data)
      .catch(err => err)
  },
  recoveryPasswordSubmit(email, code, newPassword) {
    return Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(data => data)
      .catch(err => err)
  },
  changePassword(oldPassword, newPassword, username) {
    return Auth.signIn(username, oldPassword)
      .then(() =>
        Auth.currentAuthenticatedUser()
          .then(user => Auth.changePassword(user, oldPassword, newPassword))
          .then(() => Auth.signOut())
          .then(result => ({ ...result, status: 'OK' }))
          .catch(err => err),
      )
      .catch(err => err)
  },
  currentAccount() {
    return Auth.currentAuthenticatedUser()
      .then(user => user)
      .catch(err => err)
  },
}

export default awsAmplifyService
