import { API, Auth } from 'aws-amplify'
import axios from "axios";

const axiosAuthHeader = () => {
  return Auth.currentAuthenticatedUser()
    .then((user) => ({
      AuthorizationInfo: user.signInUserSession.idToken.jwtToken
    }))
    .catch((err) => console.log(err));
}

const imagesApi = {
  getImage(id) {
    return API.get('NODE_SERVICE_API', `/images/${id}`)
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  get() {
    return API.get('NODE_SERVICE_API', '/images')
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  async create(data) {
    return axios.post(`${await API.endpoint("NODE_SERVICE_API")}/images`, data, { headers: await axiosAuthHeader() })
      .then(response => response.data)
      .catch(err => {
        console.error(err);
        return false
      })

    // return API.post('NODE_SERVICE_API', '/images', { body: data })
    //   .then(response => response)
    //   .catch(err => err)
  },
  update(data) {
    return API.put('NODE_SERVICE_API', '/images', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  delete(data) {
    return API.del('NODE_SERVICE_API', '/images', { body: data })
      .then(response => response)
      .catch(err => err)
  },
}

export default imagesApi
