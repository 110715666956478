import React from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss'

const Loader = ({ spinning = true, fullScreen }) => (
  <div
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: true,
    })}
  >
    <div>
      <img src="/resources/images/logo-white.png" alt="Loading..." />
      <div>
        <LoadingOutlined />
      </div>
    </div>
  </div>
)

export default Loader
