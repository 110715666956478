import { API } from 'aws-amplify'

const purposesApi = {
  get(emotionId) {
    return API.get('NODE_SERVICE_API', `/emotions${emotionId ? `/${emotionId}` : ''}`)
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  getGroupped() {
    return API.get('NODE_SERVICE_API', `/Emotion/grouped`)
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  create(data) {
    return API.post('NODE_SERVICE_API', '/emotions', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  update(data) {
    return API.put('NODE_SERVICE_API', '/emotions', { body: data })
      .then(response => response)
      .catch(err => err)
  },
  delete(data) {
    return API.del('NODE_SERVICE_API', '/emotions', { body: data })
      .then(response => response)
      .catch(err => err)
  },
}

export default purposesApi
