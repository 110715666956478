import { API } from 'aws-amplify'

const algosApi = {
  get() {
    return API.get('NODE_SERVICE_API', '/algo')
      .then(response => response)
      .catch(err => {
        console.error(err)
        return []
      })
  },
  update(data) {
    return API.put('NODE_SERVICE_API', '/algo', { body: data })
      .then(response => response)
      .catch(err => err)
  },
}

export default algosApi
